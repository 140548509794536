<template>
    <div class="serviceCategory">
        <div class="serviceCategoryIntro bg-icons">
            <div
                class="c w-l flex flex-wrap justify-between align-center"
            >
                <div class="aboutintro__text w-12 m-up:w-7 l-up:w-6 mb-xl m-down:py-xxl m-down:my-xxl s-down:text-center">
                    <h2 class="size-3">{{ serviceCategory.title }}</h2>
                    <div class="color-secondary" v-html="serviceCategory.description" />
                </div>

                <div class="aboutintro__image1 relative w-10 s:w-8 s-down:mx-auto m-up:w-5" v-if="serviceCategory.image1">
                    <div>
                        <img :srcset="imageSizes(cdnUrl(serviceCategory.image1), [400, 800, 1200])" class="rounded-m" />
                    </div>
                    <img src="/img/ampersand.svg" alt="Mobers & Smolenaers Icon" class="aboutintro__ampersand" />
                </div>
            </div>
        </div>

        <ServicesLinked
            :ids="services"
            title="U kunt bij ons onder andere terecht voor"
        />
        <About />
    </div>
</template>

<script lang="ts">
import {
    defineComponent, computed, PropType, ComputedRef, inject, watch,
} from 'vue';
import { METADATA } from '@projectfive/v-meta';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import About from '@/components/About.vue';
import ServicesLinked from '@/components/ServicesLinked.vue';
import useTexts from '../composables/texts';
import { ServiceCategory } from '../types';

export default defineComponent({
    name: 'ServiceCategory',
    components: {
        ServicesLinked,
        About,
    },
    props: {
        category: {
            type: String as PropType<'huis'|'bedrijf'|'familie'>,
            required: true,
        },
    },
    setup(props) {
        const serviceGroupsOrder = ['huis', 'bedrijf', 'familie'];
        const catID = computed(() => {
            const catIndex = serviceGroupsOrder.findIndex((it) => it === props.category);
            return catIndex + 1;
        });

        const { services, texts } = useTexts();
        const serviceCat: ComputedRef<ServiceCategory> = computed(() => {
            if (texts.value && texts.value.services_categories) {
                return texts.value.services_categories.find((it) => it.id === catID.value);
            }
            return {} as ServiceCategory;
        });

        const servicesInCat = computed(() => {
            if (services.value) { return services.value.filter((it) => it.category === catID.value).map((it) => it.id); }
            return [];
        });

        const metadata = inject(METADATA);
        watch(serviceCat, setMeta, { immediate: true });
        function setMeta() {
            // @ts-ignore
            metadata.set({
                metadata: {
                    title: serviceCat.value.title,
                    description: serviceCat.value.subtitle,
                },
            });
        }

        return {
            services: servicesInCat,
            serviceCategory: serviceCat,
            texts,

            cdnUrl,
            imageSizes,
        };
    },
});
</script>
<style>
    .serviceCategoryIntro { padding: 3vh 0 2vh 0; }
</style>
