
import { defineComponent, inject } from 'vue';
import { METADATA } from '@projectfive/v-meta';
import ServicesOverview from '@/components/Services.vue';
import About from '@/components/About.vue';

export default defineComponent({
    name: 'Services',
    components: {
        ServicesOverview,
        About,
    },
    setup() {
        const metadata = inject(METADATA);
        // @ts-ignore
        metadata.set({
            metadata: {
                title: 'Diensten',
            },
        });
    },
});
