
import {
    defineComponent, computed, PropType, ComputedRef, inject, watch,
} from 'vue';
import { METADATA } from '@projectfive/v-meta';
import { imageSizes } from '@projectfive/services';
import { cdnUrl } from '@/services/cdnUrl';
import About from '@/components/About.vue';
import ServicesLinked from '@/components/ServicesLinked.vue';
import useTexts from '../composables/texts';
import { ServiceCategory } from '../types';

export default defineComponent({
    name: 'ServiceCategory',
    components: {
        ServicesLinked,
        About,
    },
    props: {
        category: {
            type: String as PropType<'huis'|'bedrijf'|'familie'>,
            required: true,
        },
    },
    setup(props) {
        const serviceGroupsOrder = ['huis', 'bedrijf', 'familie'];
        const catID = computed(() => {
            const catIndex = serviceGroupsOrder.findIndex((it) => it === props.category);
            return catIndex + 1;
        });

        const { services, texts } = useTexts();
        const serviceCat: ComputedRef<ServiceCategory> = computed(() => {
            if (texts.value && texts.value.services_categories) {
                return texts.value.services_categories.find((it) => it.id === catID.value);
            }
            return {} as ServiceCategory;
        });

        const servicesInCat = computed(() => {
            if (services.value) { return services.value.filter((it) => it.category === catID.value).map((it) => it.id); }
            return [];
        });

        const metadata = inject(METADATA);
        watch(serviceCat, setMeta, { immediate: true });
        function setMeta() {
            // @ts-ignore
            metadata.set({
                metadata: {
                    title: serviceCat.value.title,
                    description: serviceCat.value.subtitle,
                },
            });
        }

        return {
            services: servicesInCat,
            serviceCategory: serviceCat,
            texts,

            cdnUrl,
            imageSizes,
        };
    },
});
